import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../css/profile.css'
import '../css/studentJuri.css'
import axios from 'axios';
import styled from 'styled-components'
import qs from 'qs';
import config from '../config'
//  import youjian from '../../public/img/youjian.jpg'
import { toggleUserDropdown } from '../redux/Actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';


const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
const tableStyle = {
    border: '1px solid #eee'
}

const Button = styled.div`
       color: #fff;
    cursor: pointer;
    text-align: center;
    width: 90px;
    margin: 0 10px;
    background: #5190fd;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size:16px
`

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        padding: 0

    },
    body: {
        fontSize: 18,
        padding: 0,
        width: "50%"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
}))(TableRow);

const table = {
    minWidth: 700,
    padding: "0 30px",
    marginTop: "10px"
}
export class type extends Component {
    state = {
        userId: '',
        rows: [
        ],  // 学生数据
        rowsPerPage: 10,
        page: 0,
        count: 0,  // 查询的总数量
        pid: '0',
        addModal: false,
        typename: '',
        addClassModal: '',
        contentS: '',
        courseJson: [],
        courseArray: [],
        tid: ""
    }
    componentDidMount() {
        console.log(this.props)
        this.getData()
    }
    getData = () => {
        const { pid, page } = this.state
        let params = {
            pid: pid,
            page: page + 1,
        }
        axios.post(`${config.env.edu}getType`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                rows: res.data[0]
            })
            // }
        })
    }

    getCType = (id) => {
        this.setState({
            page: 0,
            pid: id,
        }, () => {
            this.getData();
        })

    }

    submit = () => {
        const { pid, typename } = this.state
        if (typename == '') {
            window.U.Alert('分類名稱不能為空')
            return;
        }
        let params = {
            pid: pid,
            n: typename,
            uid: this.props.userId
        }
        axios.post(`${config.env.edu}addType`, qs.stringify(params)).then(res => {
            if (res.status === 200) {
                this.setState({
                    addModal: false,
                    typename: ""
                }, () => {
                    this.getData()
                })
                window.U.Alert('添加成功')
                this.state.classname = "";
            } else {
                window.U.Alert('添加失敗')
            }
        }).catch(err => {
            window.U.Alert('添加失敗')
        })
    }


    del = (id) => {
        var cid = id;
        var _this = this
        window.U.UF.UI.confirm("確定刪除此班級嗎?", function () {
            let params = {
                tid: cid,
            }
            axios.post(`${config.env.edu}deleteType`, qs.stringify(params)).then(v => {
                if (v.data === 1) {
                    _this.getData()
                    window.U.Alert('刪除成功')
                } else {
                    window.U.Alert('刪除失敗')
                }
            })
        }, function () {
            return;
        })
    }

    addCourse = (id) => {
        this.setState({
            addClassModal: true,
            tid: id
        }, () => {
            this.getCourse();
        })
    }

    getCourse = (type) => {
        const { contentS, tid } = this.state
        let params = {
            content: contentS,
            tid: tid,
        }
        axios.post(`${config.env.edu}getTypeCourse`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                courseJson: res.data[0]
            })
            // var array = ['36d75df3-252a-385c-4b75-29949d8d1d4d', 'deef1f8c-e50b-f637-4dae-a23e542e6977', '10797031-9e94-2b44-ef19-2f6d7ce06e20', 'd68662d6-214b-e697-21fa-883d9a7e2733', '29acbfa4-f542-2b1d-7b4d-b2225d3020de', '5415ebc1-5501-20b0-28fb-468a2c08c0d5', '053ae443-cbfe-f8dc-4848-1baa66a5b7e1', 'e8e4a28f-3aba-9511-e66a-db346f3a3c56', '4630de59-1fc1-11ea-b44a-028edca3b798', '82db0716-60bc-cd17-a034-5a3e8fefccd2', '618ceffb-760b-fd0c-d756-895aefd5c968', '83b64186-8a1c-95f7-2040-e1d6fe86ea3f', '02e8aa14-8918-dafb-bc96-8cee2a053fc3', '4b774015-1d83-11ea-b44a-028edca3b798', '8f21aa9b-cc62-c7e6-7eb4-67b7dbc0fb72', '63d7d464-1fba-11ea-b44a-028edca3b798', 'ec37f7ae-4190-e435-10e4-54141f2711db', '68aaddb9-b3a9-b807-6bac-cf7c7e0dd990', '8ab56844-a59b-ad4e-5178-e6c0875b55bc', '87976d55-1d9b-11ea-b44a-028edca3b798', 'caa35fae-90d8-8707-3d12-45da2c515485', 'f4a45b3a-1fba-11ea-b44a-028edca3b798', '52ff1dca-f0be-11e9-96f9-028edca3b798', '7083f84e-ce24-11e9-a839-028edca3b798', 'c85cafd1-67a1-11ec-89d1-02e95ef702ec', '89f8358a-88ee-c7cd-5949-50ef874e9d23', '801336da-cc51-98f5-78e4-7c93ffe4a4e3', 'cbbd4faf-e768-9002-7dce-722b55ab4d84', '6f809ddb-548b-78fc-8ee0-482e050d8e86', '0bab67e7-4446-4005-7ff0-9c5267c4cd32', '801be263-6012-eb71-5c76-fce42e42320d', 'e7f30b45-5c51-11ea-801b-028edca3b798', '9f9c21cd-b29e-4802-a3c5-716461e80a74', 'd5441a60-f569-87de-1558-95c0ca481d0f', '4067b437-6e5e-11ea-801b-028edca3b798', 'abff21d5-ddcf-abb0-4632-c671f1730769', '848d5beb-7ad8-11ea-801b-028edca3b798']
            var array = []
            if (res.data[1].length > 0) {
                for (var i = 0; i < res.data[1].length; i++) {
                    array.push(res.data[1][i].courseid);
                }
            }
            if (type != '1') {
                this.setState({
                    courseArray: array
                })
            }
            console.log(array)
            // }
            // }
        })
    }
    addSJosn = (cid) => {
        let { courseArray } = this.state
        if (courseArray.indexOf(cid) !== -1) {
            courseArray.splice(courseArray.indexOf(cid), 1)
        } else {
            courseArray.push(cid)
        }

        this.setState({
            courseArray: courseArray
        })
    }

    addCourseSubmit = () => {
        const { courseArray, tid } = this.state
        let params = {
            course: courseArray.join(","),
            tid: tid,
            uid: this.props.userId
        }
        axios.post(`${config.env.edu}addCourseType`, qs.stringify(params)).then(v => {
            this.getData()
            this.setState({
                addClassModal: false,
                tid: '',
                courseArray: []
            })
            window.U.Alert('添加成功')
        }).catch(err => {
            window.U.Alert('添加失敗')
        })
    }
    render() {
        const { rows, page, rowsPerPage, count, pid, addModal, typename, addClassModal, contentS, courseJson, courseArray } = this.state;
        return (
            <div className="AddStudent">
                <div className="pb_head student">
                    分類管理
                    {pid != '0' ?
                        (<span><button onClick={() => { this.setState({ addModal: true }) }} >添加分類</button><button onClick={() => this.getCType(0)} >返回</button></span>)
                        :
                        ""}
                </div>
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">分類</StyledTableCell>
                                <StyledTableCell align="center">操作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.pid == '0' ? <Button onClick={() => this.getCType(row.id)}>查看分類</Button> : ''}
                                        {row.pid != '0' ? <span>
                                            <Button onClick={() => this.addCourse(row.id)}>添加課程</Button>
                                            <Button onClick={() => this.del(row.id)}>刪除</Button>
                                        </span> : ''}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table> : <div style={{ textAlign: 'center', }}>
                        <img src={require('../assets/img/notcontent.png')} />
                    </div>}
                    {rows.length > 0 && <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="每頁顯示條數"
                    />}
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addModal}
                    onClose={() => {
                        this.setState({
                            addModal: false
                        })
                    }}
                >
                    <Fade in={addModal}>
                        <div className="addStudentModal" style={{ width: "470px" }}>
                            <div>
                                <h2>添加分類</h2>
                                <span className="sspan" onClick={() => { this.setState({ addModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul style={{ margin: '20px auto' }}>
                                <li>
                                    <span class="addName">分類名稱</span>：
                                    <input style={{ width: "auto" }} className="inputs" value={typename} placeholder='請輸入分類名稱' onChange={e => { this.setState({ typename: e.target.value }) }} /> &nbsp;
                                </li>
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={() => { this.submit() }}>確定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={modalStyle}
                    open={addClassModal}
                    onClose={() => {
                        this.setState({
                            addClassModal: false
                        })
                    }}
                >
                    <Fade in={addClassModal}>
                        <div className="addStudentModal" style={{ width: "455px" }}>
                            <div>
                                <h2>添加分類</h2>
                                <span className="sspan" onClick={() => { this.setState({ addClassModal: false }) }}><img style={{ width: '30px' }} src={require("../assets/img/manage/cancel.png")} /></span>
                            </div>
                            <ul>
                                <li>
                                    <div className="sj_panel">
                                        <div className="sj_panel_search" >
                                            <input type="text" value={contentS} placeholder="輸入需要搜索的課程名稱" onChange={(e) => {
                                                this.setState({
                                                    contentS: e.target.value
                                                })
                                            }} />
                                            <span onClick={() => { this.getCourse('1') }}>搜索</span>
                                        </div>
                                        <div className="sj_panel_users">
                                            {courseJson.length > 0 ? courseJson.map((v, i) => (
                                                <div className="sj_panel_user" key={i} onClick={() => { this.addSJosn(v.courseId) }}>
                                                    <input type="checkbox" checked={courseArray.indexOf(v.courseId) !== -1} />
                                                    <span style={{ width: "calc(100% - 23px)", textAlign: "left" }} title={v.title + ' 編號:' + v.number}>{v.title + ' 編號:' + v.number}</span>
                                                </div>
                                            )) :
                                                <div className="sj_panel_user">
                                                    暫無課程
                                                </div>}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="submit">
                                <button style={{ background: '#F23A3A' }} onClick={() => { this.addCourseSubmit() }}>確定</button>
                                <button style={{ background: '#AAA' }} onClick={() => { this.setState({ addClassModal: false }) }}>取消</button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}



export default type
