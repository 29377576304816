import React from 'react'
import Slider from "react-slick";
import styled from  'styled-components';
import '../assets/css/Slider.css'
import {Link} from 'react-router-dom'
//config
import config from '../config'
import img1 from '../assets/img/1.png'
import img3 from '../assets/img/3.png'
import img6 from '../assets/img/6.png'

const Wrapper = styled.div`
  grid-area:${props=>props.areaname};
  height:33vw;
  @media screen and (max-width:767px){
    height:50vw;
  }
`;
const ImgWrapper = styled.div`
  @media screen and (max-width:1023px){
      height:200px;
      width:100%;
  }
`;
class Carousel extends React.Component {
  state = {
    imgs:[
      {
        url:"https://airrc.plkflpy.edu.hk/#schedule",
        imgSrc:img1
      },
      {
        url:"https://gba.cocorobo.hk",
        imgSrc:img3
      },
      {
        url:"",
        imgSrc:img6
      }
    ]
  }
  render () {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows:false,
      autoplay:true,
    };

    return (
      <Wrapper areaname={this.props.areaname}>
        <Slider {...settings}>
          {this.state.imgs.map((x,y)=>(<a href={x.url} target="_blank" key={`Banner__${y}`} >
              <ImgWrapper>
                <img
                  src={x.imgSrc}
                  alt={`Banner__${y}`}
                  css={`
                    width:100%;
                    height:100%;
                    object-position:50% 100%;
                    object-fit:cover;
                    `}/>
              </ImgWrapper>
             </a>
          ))}
        </Slider>
      </Wrapper>
    )
  }
}

export default Carousel;
