import React from 'react'
import styled from 'styled-components';
//material-ui
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
//responsive
import MediaQuery from 'react-responsive';
import Responsive from 'react-responsive';
import axios from 'axios';
import config from '../config'



const Wrapper = styled.div`
  grid-area:${props => props.areaname};
  height:100%;
  position:relative;
`;

const styles = {
  card: {
    maxWidth: 345,
    height: '100%',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  action: {
    height: "100%"
  },
  content: {
    height: "100%",
    padding: 8
  }
};

class CourseCard extends React.Component {

  state={
    show:false
  }

  componentDidMount=()=>{
    
  }

  render() {
    const { classes, poster, title, intro,state } = this.props;
    return (
      <Wrapper>
        <Card className={classes.card}>
          <CardActionArea className={classes.action}>
            {poster ? (
              <React.Fragment>
                <Responsive minWidth={1043}>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    image={poster}
                    title={title}
                    height="180"
                    src='img'
                  />
                </Responsive>
                <Responsive minWidth={768} maxWidth={1042}>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    image={poster}
                    title={title}
                    height="150"
                    src='img'
                  />
                </Responsive>
                <Responsive maxWidth={767}>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    image={poster}
                    title={title}
                    height="80"
                    src='img'
                  />
                </Responsive>
                {/*<MediaQuery query="(max-device-width:1023px)">
                <CardMedia
                component="img"
                className={classes.media}
                image={poster}
                title={title}
                height="80"
                src='img'
                />
              </MediaQuery>*/}
              </React.Fragment>
            ) : ''}
            <CardContent className={classes.content}>
              <MediaQuery query="(min-device-width: 1043px)">
                <Typography gutterBottom variant="subtitle1" >
                  {title}
                </Typography>
                <Typography variant="body1" >
                  {intro}
                </Typography>
              </MediaQuery>
              <MediaQuery query="(max-device-width: 1043px)">
                <Typography variant="subtitle2" style={{ fontSize: 14 }} >
                  {title}
                </Typography>
                <Typography variant="body1">
                  {intro}
                </Typography>
              </MediaQuery>
            </CardContent>
          </CardActionArea>
        </Card>
      </Wrapper>
    )
  }
}

export default withStyles(styles)(CourseCard);
