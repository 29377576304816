import React from 'react'
import styled from 'styled-components'

/*
@props
title
thumbnail
source
*/
const Wrapper = styled.div`
  margin:0;
  position:relative;
  width:100%;
  height: 100%;
  background-color:${props => props.backgroundColor || '#fff'};
  border-radius:10px;
  overflow:hidden;
  cursor:pointer;
  grid-area:${props => props.areaname};
`;

const Video = styled.video`
  width:100%;
  height: 100%;
  background-color: #252525;
`;

const Img = styled.img`
  width:100%;
  height: 100%;
  background-color: #252525;
`;
class Clip extends React.Component {
  render() {
    return (
      <Wrapper
        areaname={this.props.areaname}
        backgroundColor={this.props.backgroundColor}
        {...this.props}
      >
        {
          this.props.source && ".avi,.wmv,.mpg,.mpeg,.mov,.rm,.ram,.swf,.flv,.mp4,.mp3,.wma,.avi,.rm,.rmvb,.flv,.mpg,.mkv".indexOf(this.props.source.substring(this.props.source.lastIndexOf("."))) > -1
            ? <Video
              controls
              src={this.props.source}
              poster={this.props.poster}
            >
            </Video> :
            <Img
              src={this.props.source}
              alt={this.props.poster}
            >
            </Img>
        }
      </Wrapper>
    )
  }
}

export default Clip;
